import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src235949290/src/1CPD-QR-generator/src/components/layout.tsx";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Generate QR Codes programmatically" mdxType="SEO" />
    <h1>{`Generate QR Codes Programmatically`}</h1>
    <p>{`The 1CPD mobile app supports scanning QR codes to prepopulate its record an activity
form. Those codes can be generated in app, online by anyone, or by programmatically
writing your own as shown below.`}</p>
    <p>{`Each QR code is an image representation of the `}<inlineCode parentName="p">{`onecpd://`}</inlineCode>{` URL scheme and is followed by
the following optional key value pairs, all URL encoded:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`activity_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string representation of the RCVS CPD activity type that best represents the activity conducted. For a current list of activity types please `}<a parentName="td" {...{
              "href": "https://cpd.rcvs.org.uk/api/activity_type/"
            }}>{`view our API`}</a>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string representation of the date the activity was completed in the following format: YYYY-MM-DD`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end_date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Same as `}<inlineCode parentName="td">{`date`}</inlineCode>{` but must be at least one day after date. Exclude if the same as date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`minutes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An integer representing the total number of minutes devoted to the activity. `}<strong parentName="td">{`Must`}</strong>{` be divisible by 15.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`note`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string containing any miscellaneous notes on the activity conducted. Maximum 4000 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`other_activity_type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string describing the `}<inlineCode parentName="td">{`activity_type`}</inlineCode>{` if `}<inlineCode parentName="td">{`activity_type`}</inlineCode>{` is set to "Other". Maximum 80 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`topic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A string that serves as the title of the activity. Maximum 80 characters.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`An example of a properly formatted QR code URL is as follows:`}</p>
    <pre><code parentName="pre" {...{}}>{`onecpd://activity_type=Distance%20learning%20-%20webinar&date=2020-01-27&minutes=75&note=My%20long%20notes%20about%20this%20activity.&topic=My%20topic
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      